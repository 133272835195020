<template>
    <div class="main">
        <div class="head">
            <div class="title">CVBD·智慧可视化大屏</div>
            <div class="headerRightView">
                <!-- <i class="icon-zhengshuguanli iconTX iconfont"></i> -->
                <el-dropdown trigger="click" @command="outLoginClick" @visible-change="logOut">
                    <div class="userbox">
                        <img class="user" src="../assets/home/user.png" alt="">
                        <div class="iconView">
                            <!-- <img src="https://s3.bmp.ovh/imgs/2021/08/473fb88e01aa65bb.jpg" alt /> -->
                            <div class="headerTitle">管理员</div>
                            <i :class="iconTransform == true
                    ? 'iconTransform iconfont iconMargin icon-weibiaoti-'
                    : 'iconfont iconTransformOff iconMargin icon-weibiaoti-'
                    " style="color:#D2D2D2"></i>
                        </div>
                    </div>

                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="2">修改密码</el-dropdown-item>
                        <el-dropdown-item command="1">退出</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="nav">
            <div class="n-li">大屏管理</div>
        </div>
        <div class="list">
            <div v-for="(item, index) in list" :key="index">
                <div class="imgbox" @mouseover="showOverlay" @mouseout="hideOverlay">
                    <img :src="item.coverImg" alt="">
                    <div v-show="isOverlayVisible" class="test">
                        <div>有效期至{{ item.endTime }}</div>
                        <div class="look" v-if="item.isValid" @click="openUrl(item.id)">预览</div>
                        <div class="look" v-if="!item.isValid" @click="openUrls()">续费</div>
                    </div>

                </div>
                <ul class="list-ul">
                    <li>{{ item.name }}</li>
                    <li class="mini" v-if="item.isValid">有效</li>
                    <li class="mini" v-if="!item.isValid">无效</li>
                </ul>
            </div>
        </div>
        <el-dialog title="修改密码" :visible.sync="dialogVisible" width="30%">
            <div class="box">
                <div style="width: 108px;">新密码</div>
                <el-input style="width: 318px;margin-left:20px" placeholder="请输入新密码" v-model="oldVal"></el-input>
            </div>
            <div class="box" style="margin-top: 18px;">
                <div style="width: 108px;">确认新密码</div>
                <el-input style="width: 318px;margin-left:20px" placeholder="请再次输入新密码" v-model="newVal"></el-input>
            </div>
            <div class="btn">
                <div @click="dialogVisible = false">取 消</div>
                <div @click="sure">确 定</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getUserProjectList, getSignedUrl,resetPassword } from "../utils/http";
export default {
    data() {
        return {
            iconTransform: true,
            isOverlayVisible: false,
            dialogVisible: false,
            oldVal: '',
            newVal: '',
            list: [],
        }
    },
    created() {
        this.getList()
    },
    mouted() {

    },
    methods: {
        openUrl(id) {
            // if()
            getSignedUrl({ id }).then(res => {
                if (res.data.resultStates != 0) {
                    this.$message.error('获取数据失败')
                    return
                }
                window.open(res.data.content)
            })
        },
        openUrls() {
            this.$message.error('暂未开放')
        },
        async sure() {
            if (!this.oldVal) {
                this.$message.error('请输入新密码')
                return
            }
            if (!this.newVal) {
                this.$message.error('请再次输入新密码')
                return
            }
            if (this.newVal != this.oldVal) {
                this.$message.error('两次密码不一致')
                return
            }
            const { data: res } = await resetPassword({ password: this.oldVal })
            console.log(res)
            if (res.resultStates != 0) {
                this.$message.error(res.message)
                return
            }
            this.$message.success('修改成功')
            this.dialogVisible = false
        },
        getList() {
            getUserProjectList({}).then(res => {
                console.log(res, '====')
                if (res.data.resultStates != 0) {
                    this.$message.error('获取数据失败')
                    return
                }
                this.list = res.data.content
            })
        },
        showOverlay() {
            this.isOverlayVisible = true;
        },
        hideOverlay() {
            this.isOverlayVisible = false;
        },
        logOut(event) {
            this.iconTransform = event;
        },
        outLoginClick(command) {
            if (command == 1) {
                this.$confirm("此操作将退出登录, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$store.commit('set_token', '')
                        sessionStorage.clear();
                        this.$router.push("/login");
                    })
                    .catch(() => { });
            } else {
                this.oldVal = ''
                this.newVal=''
                this.dialogVisible = true
            }
        },
    },
}
</script>
<style scoped lang='scss'>
.main {
    background: #000000;
    height: 100vh;
}

.head {
    background: url('../assets/home/h1.png') no-repeat;
    background-size: 100% 100%;
    height: 200px;
    background-position: center center;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 40px;
}

.title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 52px;
    color: #FFFFFF;
    box-sizing: border-box;
    padding-top: 40px;
}

.iconView {
    display: flex;
    align-items: center;
}

.headerTitle {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #D1D1D1;
    margin-right: 38px;
}

.userbox {
    display: flex;
    box-sizing: border-box;
    padding-top: 26px;
}

.user {
    width: 40px;
    height: 40px;
    margin-right: 16px;
}

.nav {
    background: url('../assets/home/n1.png') no-repeat;
    background-size: 100% 100%;
    height: 50px;
    background-position: center center;
    margin-top: -50px;
}

.list {
    display: flex;
    box-sizing: border-box;
    padding: 40px;
}

.list>div {
    width: 268px;
    height: 190px;
    background: #414141;
    border-radius: 6px;
    border: 1px solid #757575;

}

.list>div {
    margin-right: 40px;
}

.n-li {
    font-family: PingFang-SC, PingFang-SC;
    font-weight: 800;
    font-size: 20px;
    color: #6CEBEC;
    background: url('../assets/home/l1.png');
    height: 50px;
    background-position: center center;
    line-height: 50px;
    text-indent: 40px;
}

.imgbox {
    position: relative;
}

.imgbox>img {
    width: 266px;
    height: 148px;
}

.test {
    position: absolute;
    width: 266px;
    height: 148px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 6px 6px 0px 0px;
    top: 0;
    left: 0;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 13px;
    color: #C5C5C5;
}

.test>div:nth-of-type(1) {
    box-sizing: border-box;
    padding: 10px;
}

.look {
    width: 84px;
    height: 32px;
    background: #009DC8;
    border-radius: 2px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    line-height: 32px;
    margin: 50px auto;
    cursor: pointer;
}

.list-ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    color: #FFFFFF;
    box-sizing: border-box;
    padding: 0 10px;
}

.mini {
    font-size: 14px;
}

.box {
    display: flex;
    align-items: center;
}

.box>div {
    text-align: right;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 400;
    font-size: 18px;
    color: #E5E5E5;
}

/deep/ .el-input__inner {
    width: 318px;
    height: 37px;
    border-radius: 2px;
    border: 1px solid #CCCCCC;
    background: none;
    color: #fff;
}

/deep/ .el-dialog {
    width: 560px;
    height: 310px;
    background: #303640;
    border-radius: 8px;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #979797;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
}

/deep/ .el-dialog__title {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 500;
    font-size: 24px;
    color: #E4E4E4;
}

.btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    cursor: pointer;
}

.btn>div {
    margin-right: 30px;
    width: 120px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #AAAAAA;
    text-align: center;
    line-height: 40px;
    color: #D3D3D3;
}

.btn>div:nth-of-type(2) {
    width: 120px;
    height: 40px;
    background: #009DC8;
    border-radius: 2px;
    border: none;
    color: #fff;
}
</style>